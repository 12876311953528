import { createStore } from 'vuex'
import lovModule from './lov.store'
import executionModule from '../../../commonvue/src/store/execution.store'
import watfocusModule from '../../../commonvue/src/store/watfocus.store'
import watuxModule from '../../../commonvue/src/store/watux.store'
export interface State {
  executionModule: any;
  watuxModule: any;
  watfocusModule: any;
}

export default createStore<State>({
  modules: {
    executionModule:executionModule,
    lovModule:lovModule,
    watuxModule: watuxModule,
    watfocusModule: watfocusModule
  },
});
