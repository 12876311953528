import ApiService from '../services/api.service'
import { VueCookieNext } from 'vue-cookie-next'

export const FETCH_FOCUS_DATA_MODERATOR = "FETCH_FOCUS_DATA_MODERATOR"
export const FETCH_FOCUS_DATA_OBS = "FETCH_FOCUS_DATA_OBS"
export const FETCH_TESTER_JOIN_SLOT = "FETCH_TESTER_JOIN_SLOT"
export const GRANT_TESTER_ACCESS = "GRANT_TESTER_ACCESS"
export const FETCH_WAT_FOCUS_DATA = "FETCH_WAT_FOCUS_DATA"
export const FETCH_ONLINE_USERS = "FETCH_ONLINE_USERS"
export const FETCH_WAT_FOCUS_USERS = "FETCH_WAT_FOCUS_USERS"
export const SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY = "SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY"
export const SAVE_OBSERVATOR_NAME = "SAVE_OBSERVATOR_NAME"
export const SET_ONLINE_USERS = "SET_ONLINE_USERS"

const state = {
  onlineUsers:{}
}

const getters = {
  onlineUsers(state: any){
    return state.onlineUsers
  }
}

const actions = {
  async FETCH_FOCUS_DATA_MODERATOR(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/videochatdata`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_FOCUS_DATA_OBS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/videochatdataobs`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async GRANT_TESTER_ACCESS(context:any, payload:any) {
    return ApiService
      .post(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/tester/${payload.testerId}`, payload.grantAccess)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_TESTER_JOIN_SLOT(context:any, payload:any) {
    return ApiService
      .get(`/tester/watfocus/${payload.id}/slot/${payload.slotHash}`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_WAT_FOCUS_DATA(context:any, payload:any) {
    return ApiService
      .get(`/watFocus/${payload.id}/slot/${payload.slotHash}`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_ONLINE_USERS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/onlineusers`, null)
      .then(({data}: any) => {
        context.commit(SET_ONLINE_USERS,data.onlineUsers)
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async FETCH_WAT_FOCUS_USERS(context:any, payload:any) {
    return ApiService
      .get(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/users`, null)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async SAVE_WAT_FOCUS_PREVIOUS_CONNECTIVITY(context:any, payload:any) {
    return ApiService
      .post(`/tester/watfocus/${payload.id}/slot/${payload.slotHash}/connectivity`, payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  },
  async SAVE_OBSERVATOR_NAME(context:any, payload:any) {
    return ApiService
      .post(`/client/watfocus/${payload.id}/slot/${payload.slotHash}/observator`, payload.data)
      .then(({data}: any) => {
        return data
      })
      .catch(({response}: any)  => {
        return Promise.reject(response)
      })
  }
}

const mutations = {
  
  SET_ONLINE_USERS(state: any,data: any){
    state.onlineUsers = data;
  },
}

export default{
  actions,
  state,
  getters,
  mutations
}
