
import { computed, defineComponent, inject, ref } from 'vue'
import ProfilePictureBox from './ProfilePictureBox.vue'
import ProfileProgressBar from './ProfileProgressBar.vue'
import ProfilePointsBoxMobile from './ProfilePointsBoxMobile.vue'
import { onClickOutside } from '@vueuse/core'

export default defineComponent({
    components: {ProfilePictureBox, ProfileProgressBar, ProfilePointsBoxMobile},
    setup() {
        const authStore: any = inject('authState');
        const configurationStore: any = inject('configurationStore');
        const target: any = ref(null)
        onClickOutside(target,(event: any)=>{
            if (event.target !== null && event.target.id !== 'profileIconContainer') {
                configurationStore.setShowProfileBox(false);
            }
        })
        return{
            userData : computed( ( ) => authStore.state.userData),
            target,
        }  
    },
})
