import { reactive, readonly } from 'vue'
import { AxiosResponse } from 'axios'
import axios from '@/config/axiosConfig'
import { Storage } from '@capacitor/storage'
import recordStore from '@/store/record.store'
import i18n from '@/i18n'

const state = reactive({
    validPrefixList:[{value:34, country:'es'}, {value:351, country:'pt'}],
    isLoggedIn: false,
    appToken: '',
    authToken: '',
    deviceRegistered: false,
    userDataLoaded: false,
    userData: {
        identifier: null,
        identityDocType: {
            elementId: '',
        },
        identityDocNum: '',
        gender: '',
        nickname: '',
        name: '',
        surname1: '',
        surname2: '',
        picture: '',
        province: {
            id: '',
        },
        address: '',
        bornDate: '',
        locality: '',
        postalCode: '',
        residenceCountry: '',
        mobileValidated: false,
        mobile: '',
        mobileNum: '',
        mobilePrefix: 0,
        email: null,
        emailNeedsValidation: false,
        emailValidated: false,
        profileCompleted: false,
        mobileValidationRequired: false,
    },
    signUp: {
        showError: false,
        errorMessage: '',
    },
    resetPassword: {
        showError: false,
        errorMessage: '',
    },
    send2fa: {
        showError: false,
        errorMessage: '',
    },
    termsAccepted: false,
})

const logUserOut = async function () {
    state.isLoggedIn = false
    state.authToken = ''
    state.appToken = ''

    await Storage.remove({
        key: 'authToken',
    })
    await Storage.remove({
        key: 'appToken',
    })

    state.userData = {
        identifier: null,
        identityDocType: {
            elementId: '',
        },
        identityDocNum: '',
        gender: '',
        nickname: '',
        name: '',
        surname1: '',
        surname2: '',
        picture: '',
        province: {
            id: '',
        },
        address: '',
        bornDate: '',
        locality: '',
        postalCode: '',
        residenceCountry: '',
        mobileValidated: false,
        mobile: '',
        mobileNum: '',
        mobilePrefix: 0,
        email: null,
        emailNeedsValidation: false,
        emailValidated: false,
        profileCompleted: false,
        mobileValidationRequired: false,
    }

    state.userDataLoaded = false

    recordStore.deleteUserData()
}

const getUserDetails = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/tester`)
    } catch (error: any) {
        if (error.response.status == 401) {
            logUserOut()
        }
        return
    }

    if (response?.status != 200) return
    state.userData = response?.data
    state.userDataLoaded = true
}

const loginWatAcademy = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/tester/watacademyToken`)
    } catch (error: any) {
        return Promise.reject(error)
    }

    return Promise.resolve(response?.data)
}

const loadStorageTokens = async (): Promise<void> => {
    state.appToken = (await Storage.get({ key: 'appToken' })).value || ''
    state.authToken = (await Storage.get({ key: 'authToken' })).value || ''
    state.isLoggedIn = state.appToken !== '' && state.authToken !== ''
}

const loadUserDetails = async function () {
    const x = (await Storage.get({ key: 'authToken' })).value
    if (x != null) {
        await loadStorageTokens()
        axios.defaults.headers.common['authToken'] = state.authToken
        axios.defaults.headers.common['appToken'] = state.appToken
    }
}

const logUserIn = async function (payload: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post('/tester/login', payload)
    } catch (e: any) {
        if (e.response && e.response.status == 401) {
            return {
                success: false,
                message: 'Usuario y/o contraseña incorrectos',
            }
        }
        return {
            success: false,
            message:
                'No hemos podido completar tu petición, revisa tu conexión',
        }
    }

    if (response?.data.authToken && response?.data.appToken) {
        state.isLoggedIn = true
        state.authToken = response?.data.authToken
        state.appToken = response?.data.appToken

        await Storage.set({
            key: 'authToken',
            value: state.authToken,
        })

        await Storage.set({
            key: 'appToken',
            value: state.appToken,
        })
        loadUserDetails()

        return { success: true,nextStep: response?.data.nextStep}
    }
}

const obtainCountry = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get('https://ipinfo.io/json')
        state.userData.residenceCountry = response?.data.country.toLowerCase()
        state.userData.mobilePrefix = obtainPrefixByCountryCode(state.userData.residenceCountry)
        return
    } catch (error) {
        state.userData.residenceCountry = navigator.language.split("-")[0]
        state.userData.mobilePrefix = obtainPrefixByCountryCode(state.userData.residenceCountry)
        return
    }
}

const obtainPrefixByCountryCode = function (countryCode: any) {
    let prefix = 34
    for(let vp of state.validPrefixList){
        if(vp.country == countryCode) prefix = vp.value
    }
    return prefix
}

const signUp = async function (data: any) {
    state.signUp.showError = false
    let response: AxiosResponse | undefined
    try {
        response = await axios.post('/tester', data)
    } catch (e: any) {
        let error
        if (
            e.response.data.error == 'duplicate element' ||
            (e.data && e.data.error && e.data.error == 'duplicatePaypalEmail')
        ) {
            error = i18n.global.t('register_errors.duplicateEmail')
            
        } else if (e.response.data.error == 'registrationIpFraud') {
            error = i18n.global.t('register_errors.IpFraud')
            
        } else if (e.response.data.error == 'registrationMobileFraud') {
            error = i18n.global.t('register_errors.MobileFraud')
            
        } else {
            error = i18n.global.t('register_errors.genericError')
            
        }
        return {
            showError: true,
            errorMessage: error,
        }
    }
    return true
}
const confirmUserEmail = async function (id: any, code: any) {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/${id}/activation`, {
            actionCode: code,
        })
        return true
    } catch (error) {
        return false
    }
}
const resendEmailConfirmation = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/tester/confirmation/resend`)
        return true
    } catch (error) {
        return false
    }
}

const resetPassword = async (email: string) => {
    let response: AxiosResponse | undefined
    try {
        response = await axios.post('/tester/resetPassword', { email: email })
    } catch (e: any) {
        if (e.response.data.error == 'element does not exist') {
            state.resetPassword.errorMessage =
                'No existe ningún usuario con ese email'
        }
        state.resetPassword.showError = true
        return false
    }
    return true
}
const resetNewPassword = async(payload: any)=>{
    let response: AxiosResponse | undefined
    try {
        response = await axios.post('/tester/'+payload.userId+'/resetNewPassword', payload.data)
    } catch (error) {
        state.resetPassword.errorMessage = 'El código de control proporcionado es incorrecto'
        state.resetPassword.showError = true
        return false       
    }
    return true
}
const checkResetCode = async(payload: any)=>{
    let response: AxiosResponse | undefined
    try {
        response = await axios.post('/tester/'+payload.userId+'/checkResetCode', payload.data)
    } catch (error) {
        state.resetPassword.errorMessage = 'El código de control proporcionado es incorrecto'
        state.resetPassword.showError = true
        return false       
    }
    return true
}

const validate2faCode = async(payload: any) => {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/2facode`, payload)
        state.send2fa.errorMessage = ""
    } catch (error: any) {
        if (error.response.data.payload == '2FA code not correct') {
            state.send2fa.errorMessage = "Testers_verifymobile_error"
        } else if (error.response.data.payload == '2FA attempts passed') {
            state.send2fa.errorMessage = "Testers_verifymobile_attempts"
        }
        return false
    }

    if (response?.status != 200) return false
    return true
}

const resend2facode = async() => {
    let response: AxiosResponse | undefined

    try {
        response = await axios.post(`/tester/resend2facode`)
    } catch (error: any) {
        return false
    }

    if (response?.status != 200) return false
    return true
}

const getMobileFor2FA  = async function () {
    let response: AxiosResponse | undefined

    try {
        response = await axios.get(`/tester/getMobileFor2FA`)
    } catch (error: any) {
        if (error.response.status == 401) {
            logUserOut()
        }
        return
    }

    if (response?.status != 200) return
    state.userData.mobileNum = response?.data
    state.userDataLoaded = true
}

loadUserDetails()

const getAppToken = () => state.appToken
const getAuthToken = () => state.authToken
const getUserData = () => state.userData
const setDeviceRegistered = () => {
    state.deviceRegistered = true
}


const updateUserTermsAccepted = async () =>{
    let response: AxiosResponse | undefined
    try {
        response = await axios.put(`/tester/updateTesterTerms`)
        return true
    } catch (error) {
        return false
    }

}

export default {
    state,
    logUserIn,
    getAppToken,
    getAuthToken,
    getUserData,
    getUserDetails,
    loadStorageTokens,
    setDeviceRegistered,
    logUserOut,
    obtainCountry,
    signUp,
    confirmUserEmail,
    resendEmailConfirmation,
    resetPassword,
    resetNewPassword,
    checkResetCode,
    loginWatAcademy,
    resend2facode,
    validate2faCode,
    updateUserTermsAccepted,
    getMobileFor2FA
}
