
import { computed, defineComponent, inject } from 'vue'
import ProfilePictureBox from './ProfilePictureBox.vue'
import ProfileProgressBar from './ProfileProgressBar.vue'
import ProfilePointsBox from './ProfilePointsBox.vue'

export default defineComponent({
    components: { ProfilePictureBox, ProfileProgressBar, ProfilePointsBox },
    setup() {
        const authStore: any = inject('authState')
        const notifStore: any = inject('notificationsStore')

        return {
            userData: computed(() => authStore.state.userData),
            showNotifications: async (state: boolean) =>
                await notifStore.showNotifications(state),
        }
    },
})
