
import { computed, defineComponent, inject, onBeforeMount, ref } from 'vue'
import {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal,
    IonFooter,
} from '@ionic/vue'
import NotificationsModal from '@/components/NotificationsModal.vue'
import ProfileDesktopBox from '@/components/ProfileDesktopBox.vue'
import InviteFriendsBox from '@/components/InviteFriendsBox.vue'
import InfoWatiosWatCoins from '@/components/InfoWat.vue'
import authStore from '@/store/auth.store'
import testsStore from '@/store/tests.store'
import { useRouter, useRoute } from 'vue-router'
import ContactModal from '../ContactModal.vue'
import WebFooter from './WebFooter.vue'

const componentName = 'MasterLayout'
const components = {
    IonPage,
    IonHeader,
    IonContent,
    IonToolbar,
    IonTitle,
    IonMenuButton,
    IonButtons,
    IonButton,
    IonIcon,
    IonModal,
    NotificationsModal,
    ContactModal,
    WebFooter,
    IonFooter,
    ProfileDesktopBox,
    InviteFriendsBox,
    InfoWatiosWatCoins,
}

const setup = () => {
    const notifStore: any = inject('notificationsStore')
    const recordStore: any = inject('recordStore')
    const configurationStore: any = inject('configurationStore')
    const exchangeStore: any = inject('exchangeStore')
    const inviteFriendsStore: any = inject('inviteFriendsStore')

    const showContacModal = ref(false)
    onBeforeMount(() => {
        const desktop = window.matchMedia('(min-width: 1000px)')
        const mobile = window.matchMedia('(max-width: 1000px)')
        if (mobile.matches) {
            notifStore.getNotifications(0, 10)
        } else {
            notifStore.getNotifications(0, 4)
        }
    })

    const router: any = useRouter()
    const route = useRoute()
    const goTo = (url: any) => {
        router.push({ name: url })
    }

    const showUserOptions = ref(false)
    const logOut = async function () {
        await authStore.logUserOut()
        goTo('login')
    }

    return {
        showNotifications: async (state: boolean) =>
            await notifStore.showNotifications(state),
        notifications: computed(() => notifStore.state.notifications || {}),
        notifStore,
        unseen: computed(() => notifStore.state.unseen || {}),
        userData: computed(() => authStore.state.userData),
        goTo,
        showUserOptions,
        logOut,
        showContacModal,
        route,
        recordStore,
        configurationStore,
        exchangeStore,
        inviteFriendsStore,
        selectedRedeemType: computed(
            () => exchangeStore.state.redeemBox.selectedRedeemType
        )
    }
}

export default defineComponent({
    name: componentName,
    components,
    setup,
})
