
import { computed, defineComponent, inject } from 'vue'

export default defineComponent({
    setup() {
        const authStore: any = inject('authState');

        return{
            userData : computed( ( ) => authStore.state.userData),
        }
    },
})
