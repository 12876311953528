
import { defineComponent, inject } from 'vue'

export default defineComponent({
    setup() {
        const inviteFriendsStore: any = inject('inviteFriendsStore')

        return {
            inviteFriendsStore,
        }
    },
})
