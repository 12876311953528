
import { computed, defineComponent, inject, ref } from 'vue'
import { useRouter } from 'vue-router'
import { Capacitor } from '@capacitor/core'
import { AppVersion } from '@awesome-cordova-plugins/app-version'

export default defineComponent({
    setup() {
        const authStore: any = inject('authState')
        const configurationStore: any = inject('configurationStore')

        const router: any = useRouter()
        const goTo = (url: any) => {
            router.push({ name: url })
        }
        const goToPoinstRecord = () => {
            configurationStore.state.showProfileBox = false
            goTo('record.index')
        }
        const appVersion = ref('')
        if (Capacitor.isNativePlatform()) {
            AppVersion.getVersionNumber().then((appversion: any) => {
                appVersion.value = appversion
            })
        }
        return {
            userData: computed(() => authStore.state.userData),
            goToPoinstRecord,
            appVersion
        }
    },
})
