
import { computed, defineComponent, inject } from 'vue'
import { useRouter } from 'vue-router'


export default defineComponent({
    setup() {
        const authStore: any = inject('authState')
        const router: any = useRouter()
        const goTo = (url: any) => {
            router.push({ name: url })
        }     
        
        return {
            userData: computed(() => authStore.state.userData),
            goTo,
        }
    },
})
