import { reactive }           from 'vue';
import { AxiosResponse }                from 'axios'
import axios                            from '@/config/axiosConfig'
import Swal from 'sweetalert2';

const state = reactive({
    redeem: {
        redeemFormData: {
            amount: '',
            email: ''
        },
        redeemConfirmation:{
            notEnoughPoints: false,
            showPaypalConfirmation: false,
            showSuccess: false,
            showError: false,
            comision: {},
            errorMsg: 'Ha habido un error al hacer la transferencia, prueba de nuevo o contacta con nosotros.',
            estimatedDate: '',
            cardTransactionCode: '',
        },
        modalLoading: false
    },
    redeemBox: {
        selectedRedeemType: '',
        showRedeem: false,
        types: {
            PAYPAL: 'PAYPAL',
            AMAZON: 'AMAZON',
            ATRAPALO: 'ATRAPALO',
            ASOS: 'ASOS',
            TREE_NATION:'TREE_NATION'
        },
        typesAmount: {
            amazonAmountOptions: [10, 15, 20, 25, 30, 50, 100, 150],
            paypalAmountOptions : [10, 20, 30, 40, 50, 100, 150],
            ongAmountOptions : [10, 20, 30, 40, 50],
            asosAmountOptions : [10, 15, 20, 25, 30],
            atrapaloAmountOptions : [10, 15, 20, 25, 30],
            treeNationAmountOptions : [10, 20, 30, 40, 50, 60 ,70, 80, 90, 100]
        }
    }
});

interface PointsChecker{
    redeemType: string;
    pointsRedeem: number;
}

interface PaypalRedeem{
    redeemType: string;
    pointsRedeem: number;
    paypalEmail: string;
    testerRedeemData: {
        identityDocType: {
            elementId: number;
        };
        identityDocNum: string;
        name: string;
        surname1: string;
        surname2: string;
        address: string;
        locality: string;
        postalCode: string;
    };
}

interface Redeem{
    redeemType: string | undefined;
    pointsRedeem: number;
    testerRedeemData: {
        identityDocType: {
            elementId: number;
        };
        identityDocNum: string;
        name: string;
        surname1: string;
        surname2: string;
        address: string;
        locality: string;
        postalCode: string;
    };
}

const clearRedeem = function(){
    state.redeem = {
        redeemFormData: {
            amount: '',
            email: ''
        },
        redeemConfirmation:{
            notEnoughPoints: false,
            showPaypalConfirmation: false,
            showSuccess: false,
            showError: false,
            comision: {},
            errorMsg: '',
            estimatedDate: '',
            cardTransactionCode: ''
        },
        modalLoading: false
    }
}

const check = async function(data: PointsChecker){
    let response: AxiosResponse | undefined

    try {
        response = await axios.post( `/points/redeemCheck`, data )
    } catch ( e ) {
        return false
    }

    if ( response?.status != 200 ){
        state.redeem.redeemConfirmation.showPaypalConfirmation = false;
        state.redeem.modalLoading = false;
        Swal.fire({
            title: 'Error!',
            text: 'Error al canjear WATcoins a través de Paypal',
            icon: 'error',
            confirmButtonText: 'Aceptar'
        })
        return;
    }

    state.redeem.modalLoading = false;
    state.redeem.redeemConfirmation.showError = false;
    state.redeem.redeemConfirmation.showSuccess = false;

    if (response?.data.enoughtPoints){
        state.redeem.redeemConfirmation.showPaypalConfirmation = true;
        state.redeem.redeemConfirmation.comision = response?.data.comision;
    }else{
        state.redeem.redeemConfirmation.notEnoughPoints = true;
    }
}

const paypalRedeem = async function(data: PaypalRedeem){
    let response: AxiosResponse | undefined

    try {
        response = await axios.post( `/points/redeem`, data )
    } catch ( e: any ) {
        state.redeem.modalLoading = false;
        state.redeem.redeemConfirmation.showPaypalConfirmation = false;
        state.redeem.redeemConfirmation.showSuccess = false;
        state.redeem.redeemConfirmation.showError = true;
        if (e.response.data.payload === "Points ammount not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'No tienes WATcoins suficientes para la cantidad elegida, selecciona una cantidad inferior a tus WATcoins.';
            return false
          } else if (e.response.data.payload === "CIF/NIF not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'Parece que tu número de identificación no es correcto, por favor <a href="#/configuration/datos-de-contacto">revisa tus datos de contacto</a>.';
            return false
          } else {
            state.redeem.redeemConfirmation.errorMsg = 'Ha habido un error al hacer la transferencia, prueba de nuevo o contacta con nosotros.';
            return false
          } 
    }

    if ( response?.status != 200 ){
        state.redeem.modalLoading = false;
        state.redeem.redeemConfirmation.showPaypalConfirmation = false;
        state.redeem.redeemConfirmation.showSuccess = false;
        state.redeem.redeemConfirmation.showError = true;
        if (response?.data && response?.data.payload === "Points ammount not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'No tienes WATcoins suficientes para la cantidad elegida, selecciona una cantidad inferior a tus WATcoins.';
            return false
          } else if (response?.data && response?.data.payload === "CIF/NIF not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'Parece que tu número de identificación no es correcto, por favor <a href="#/configuration/datos-de-contacto">revisa tus datos de contacto</a>.';
            return false
          } else {
            state.redeem.redeemConfirmation.errorMsg = 'Ha habido un error al hacer la transferencia, prueba de nuevo o contacta con nosotros.';
            return false
          } 
    }

    state.redeem.modalLoading = false;
    state.redeem.redeemConfirmation.showPaypalConfirmation = false
    state.redeem.redeemConfirmation.showSuccess = true;
    state.redeem.redeemConfirmation.showError = false;
    state.redeem.redeemConfirmation.estimatedDate = response?.data.estimatedDate;
    
    return true
}

const redeem = async function(data: Redeem){
    let response: AxiosResponse | undefined

    try {
        response = await axios.post( `/points/redeem`, data )
    } catch ( e: any ) {
        state.redeem.modalLoading = false;
        state.redeem.redeemConfirmation.showSuccess = false;
        state.redeem.redeemConfirmation.showError = true;
          if (e.response.data.payload === "Points ammount not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'No tienes WATcoins suficientes para la cantidad elegida, selecciona una cantidad inferior a tus WATcoins.';
            return false;
          } else if (e.response.data.payload === "CIF/NIF not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'Parece que tu número de identificación no es correcto, por favor <a href="#/configuration/datos-de-contacto">revisa tus datos de contacto</a>.';
            return false;
          } else {
            state.redeem.redeemConfirmation.errorMsg = 'Ha habido un error al hacer la transferencia, prueba de nuevo o contacta con nosotros.';
            return false;
          }
    }

    if ( response?.status != 200 ){
        state.redeem.modalLoading = false;
        state.redeem.redeemConfirmation.showSuccess = false;
        state.redeem.redeemConfirmation.showError = true;
          if (response?.data && response?.data.payload === "Points ammount not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'No tienes WATcoins suficientes para la cantidad elegida, selecciona una cantidad inferior a tus WATcoins.';
            return false;
          } else if (response?.data && response?.data.payload === "CIF/NIF not valid") {
            state.redeem.redeemConfirmation.errorMsg = 'Parece que tu número de identificación no es correcto, por favor <a href="#/configuration/datos-de-contacto">revisa tus datos de contacto</a>.';
            return false;
          } else {
            state.redeem.redeemConfirmation.errorMsg = 'Ha habido un error al hacer la transferencia, prueba de nuevo o contacta con nosotros.';
            return false;
          }
    }

    state.redeem.modalLoading = false;
    state.redeem.redeemConfirmation.showSuccess = true;
    state.redeem.redeemConfirmation.showError = false;
    state.redeem.redeemConfirmation.cardTransactionCode = response?.data.cardTransactionCode;
    state.redeem.redeemConfirmation.estimatedDate = response?.data.estimatedDate;
    state.redeem.redeemFormData.amount = '';

    return true;
}
const setActualRedeemType = (value: string)=>{
    state.redeemBox.selectedRedeemType = value;
}
const setShowRedeem = (value: boolean)=>{
    state.redeemBox.showRedeem = value;
}

export default{
    state: state,
    check,
    paypalRedeem,
    redeem,
    clearRedeem,
    setActualRedeemType,
    setShowRedeem
}