import { normalizeStyle as _normalizeStyle, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-17d03b78"),n=n(),_popScopeId(),n)
const _hoisted_1 = { id: "profilePictureBoxContainer" }
const _hoisted_2 = { id: "pictureContainer" }
const _hoisted_3 = { id: "pointsLevelContainer" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      _createElementVNode("div", {
        style: _normalizeStyle('background-image: url(' + _ctx.userData.picture + ')')
      }, null, 4)
    ]),
    _createElementVNode("div", _hoisted_3, _toDisplayString(_ctx.userData.level), 1)
  ]))
}