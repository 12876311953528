
import {
    IonPage,
    IonHeader,
    IonTitle,
    IonToolbar,
    IonButtons,
    IonButton,
    IonContent,
    IonList,
    IonItem,
    IonLabel,
} from '@ionic/vue'
import { computed, inject, onBeforeMount, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { onClickOutside } from '@vueuse/core'
import PaginationWat from '@/components/PaginationWat.vue'
import NotificationsLoading from '@/components/NotificationsLoading.vue'

export default {
    name: 'NotificationsModal',
    components: {
        IonPage,
        IonHeader,
        IonTitle,
        IonToolbar,
        IonButtons,
        IonButton,
        IonContent,
        IonList,
        IonItem,
        IonLabel,
        PaginationWat,
        NotificationsLoading
    },

    setup() {
        const router: any = useRouter()
        const notifStore: any = inject('notificationsStore')

        const notifications = computed(() => notifStore.state.notifications || {})
        const page = ref(1)
        const pageSize = ref(10)
        const totalTests = computed(() => {
            const numericCount = Number(notifStore.state.notifications.count);
            return numericCount;
        })
        const chunk = ref(5)
        const chunksNavigation = ref('fixed')

        const loadNotifications = async()=>{
            await notifStore.getNotifications(page.value -1, pageSize.value)
        }

        const target = ref(null)
        onClickOutside(target, (event: any) => {
            if (
                event.target !== null &&
                event.target.id !== 'showNotificationsButton'
            ) {
                notifStore.showNotifications(false)
            }
        })

        const showNotifications = async (state: boolean) =>
            await notifStore.showNotifications(state)

        const goToTest = async (id: string, type: string) => {
            if (type == 'REGULAR_TEST_AVAILABLE') {
                await showNotifications(false)
                router.push({ name: 'test.show', params: { id } })
            }
        }

        onBeforeMount(async () => {
            const desktop = window.matchMedia('(min-width: 1000px)')
            const mobile = window.matchMedia('(max-width: 1000px)')
            if (mobile.matches) {
                pageSize.value = 10
                chunk.value = 5
                chunksNavigation.value = 'scroll'
            } else {
                pageSize.value = 4
                chunk.value = 10
                chunksNavigation.value = 'fixed'
            }
        })

        return {
            goToTest,
            notifStore,
            notifications,
            showNotifications,
            target,
            page,
            totalTests,
            loadNotifications,
            chunk,
            chunksNavigation
        }
    },
}
